<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>JackPot</h1>
                <mat-divider></mat-divider>
                <br>
            </mat-card-title>
            <div>
                <p> Log into <a href="https://kagwirawo.co.ug/">kagwirawo</a></p>

                <mat-card-subtitle>
                    <h4>Terms and Conditions</h4>
                </mat-card-subtitle>
                <!-- <ul>
                  <li>Go to sign up and register</li>
                  <li>Deposit money</li>
                  <li>Select games</li>
                  <li>Place your stake</li>
                </ul> -->

                <mat-divider></mat-divider>
                <p>1. All event settlements are based on the result at the end of regular time. This
                    includes any added stoppage time but does not include extra time and penalty
                    shoot-out.</p>
                    
                <p>2. Predictions are open until the kick off time of the first event.</p>
                    
                <p>3. In case an event is postponed/abandoned beyond 24 hours, then we may cancel it. A
                    cancelled event is   automatically considered as a correct prediction on the
                    bettor's receipt.</p>
                    
                <p>4. If 3 or more events are cancelled in a 'Pick 13' Jackpot, or 2 or more events are
                    cancelled in a 'Pick 7' Jackpot, then all the receipts on that Jackpot are voided
                    and the participants' stakes are refunded.</p>
                    
                <p>5. All winnings will be paid out after the last event on the ticket has finished and
                    all results have been verified.</p>
                    
                <p>6. All entries must be submitted before the advertised Jackpot closure time. Any
                    entries after will be declared void with stakes refunded</p>
                    
                <p>7. A Player must select correctly all the outcomes for all events in the Jackpot to
                    win a share of the  Jackpot payout</p>
                    
                <p>8. If there is more than one winner then the Jackpot payout will be shared equally
                    among all the winners.</p>
                    
                <p>9. KAGWIRAWO reserves the right to reverse the settlement of a event if it has been
                    settled in error</p>

                <p>10. Account bonus(es) cannot be used to place jackpot bets</p>


                <mat-divider></mat-divider>
                <br>
                <mat-card-subtitle>
                    <h4>Frequently Asked Questions</h4>
                </mat-card-subtitle>

                <mat-divider></mat-divider>

                <mat-card-subtitle>
                    <h4> 1.What is A JackPot?</h4>
                </mat-card-subtitle>
                <p>Jackpot is a pool of games where punters bet on pre-selected football matches through out the week.  </p>
      
                <mat-divider></mat-divider>

                <mat-card-subtitle>
                    <h4> 2.Type of Jackpots?</h4>
                </mat-card-subtitle>
                <p> • Pick 13 at a stake of 200 (5,000,000 Jackpot)</p>
                <p>
                    In this type of Jackpot a Player must select correctly all the outcomes for all 13 matches to win/share the 5,000,000 UGX. The prizes may vary in size from week to week. The exact amounts will be disclosed once the pool has been settled. Please note that in case there is more than 1 winner, prize pool will be shared
                </p>
                
                <p> • Pick 7 at a stake of 500 (2,500,000 Jackpot)</p>
                <p>
                    In this type of Jackpot a Player must select correctly all the outcomes for all 7 matches to win/share the 2,500,000 UGX. The prizes may vary in size from week to week. The exact amounts will be disclosed once the pool has been settled. Please note that in case there is more than 1 winner, prize pool will be shared
                </p>
                
                <!-- <p> • Pick 7 at a stake of 500 (1000,000 Jackpot)</p>
                <p>
                    In this type of Jackpot a Player must select correctly all the outcomes for all 7 matches to win/share the 1,000,000 UGX. The prizes may vary in size from week to week. The exact amounts will be disclosed once the pool has been settled. Please note that in case there is more than 1 winner, prize pool will be shared
                </p> -->
      
                <mat-divider></mat-divider>

                <mat-card-subtitle>
                    <h4>3.What is a Jackpot line in betting?</h4>
                </mat-card-subtitle>
                <p>A jackpot line is the number of times a punter wishes to select the displayed options on a particular event </p>
                
                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>4.What is the minimum stake for betting on a Jackpot?</h4>
                </mat-card-subtitle>
                <p>• The minimum stake for betting on the 5 million Jackpot is 200</p>
                <p>• The minimum stake for betting on the 2.5 million Jackpot is 500</p>
                <!-- <p>• The minimum stake for betting on the 2.5 million Jackpot is 1000</p> -->
            </div>
        </div>
    </section>
</app-nav>