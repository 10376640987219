<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <img class="option" src="../../assets/img/bet-option.png" alt="" width="100%">
            <mat-card-title>
                <h1>Betting Options</h1>
                <mat-divider></mat-divider>
                <!-- <br> -->
            </mat-card-title>

            <!-- <h3></h3> -->
            <mat-card-subtitle>
                <h4><b>Match Result</b></h4>
            </mat-card-subtitle>
            <p>
                Predict the results of the matches. Predictions close at kick off. All bets apply to the full 90 minutes,
            plus injury time. Extra-time/penalty shoot-outs are not included.
            </p>

            <mat-card-subtitle>
                <h4><b>Perfect Score</b></h4>
            </mat-card-subtitle>
            <p>
                Predict the results of the matches. Predictions close at kick off. All bets apply to the full 90 minutes,
                plus injury time. Extra-time/penalty shoot-outs ARE included.
            </p>
            
            <mat-card-subtitle>
                <h4><b>Under/Over 2.5 (Full Time)</b></h4>
            </mat-card-subtitle>
            <p>
                A bet on whether the total number of goals/points in a game will be over or under the stated number 2.5, for
            example If 2 goals or less are scored in the match, "Under" bets are winners. If 3 goals or more are scored
            in the match "Over" bets are winners. Predictions close at kick off. All bets apply to the full 90 minutes,
            plus injury time. Extra-time/penalty shoot-outs are not included..
            </p>

            <mat-card-subtitle>
                <h4><b>Under/Over 1.5 (Half Time)</b></h4>
            </mat-card-subtitle>
            <p>
                A bet on whether the total number of goals in a game will be over or under the stated number 1.5, for
            example If 1 goal or less than that is scored in the match, "Under" bets are winners. If 2 goals or more are
            scored in the match "Over" bets are winners. All predictions close at half time. All bets apply to the full
            45 minutes, plus injury time.
            </p>
            

            <mat-card-subtitle>
                <h4><b>Half-time betting</b></h4>
            </mat-card-subtitle>
            <p>
                Predict the result of the first half of this match. All predictions close at kick off. All bets apply to the
            first full 45 minutes plus injury time (first half time plus injury time).
            </p>
            
            <mat-card-subtitle>
                <h4><b>Double Chance</b></h4>
            </mat-card-subtitle>
            <p>
                A bet that allows you to wager on two of the three possible results in an event with just one bet. Like Home
            win or Draw / Draw or Away win / Home Win or Away Win. All predictions close at kick off. All bets apply to
            the full 90 minutes, plus injury time. Extra-time/penalty shoot-outs are not included.
            </p>
            <p>
                Bets are settled on the following possible outcomes in a specified football match - e.g. A versus B would
                appear as:
            </p>
            
            <ul>
                <li>Home/Draw - A to win/Match to end in a draw</li>
                <li>Away/Draw - B to win/Match to end in a draw</li>
                <li>Home/Away - A to win/Rangers to win</li>
            </ul>
            <p>Normal 90 minutes betting rules apply.</p>

            <mat-card-subtitle>
                <h4><b>Odd/Even</b></h4>
            </mat-card-subtitle>
            <p>
                A bet on whether the total number of goals at the end of a game will be an odd number or an even number.
            Zero goals scored, it will be called Even. All predictions close at kick off. All bets apply to the full 90
            minutes, plus injury time. Extra-time/penalty shoot-outs are not included.
            </p>
            
            <ul>
                <li>ODD numbers are: 1, 3, 5, 7, 9, 11, 13, etc</li>
                <li>EVEN numbers are: 0, 2, 4, 6, 8, 10, 12, etc</li>
            </ul>

            <mat-card-subtitle>
                <h4><b>Handicap Betting</b></h4>
            </mat-card-subtitle>
            <p>
                In simple terms, one of the two sides is generally given a head start in terms of the number of goals. Lets
            say Team A has been given(+1 Goal), Odds are then offered based on that head start. You have betted on Team
            A, You win if your chosen team wins or draws the match according to the Full Time Match Results, If it loses
            with a one goal difference, then you lose the bet.
            </p>
            <p>
                All bets apply to the full 90 minutes, plus injury time. Extra-time/penalty shoot-outs are not included.
            </p>

            <mat-card-subtitle>
                <h4><b>Half-time/Full-time (Double result forecast betting)</b></h4>
            </mat-card-subtitle>
            <p>
                Half-time/Full-time (Double result forecast) betting is available on most matches where Home/Away/Draw
                prices are quoted. Bets are settled based on the result of the match at both half-time and full-time (i.e.
                at the end of 45 minutes and 90 minutes, including injury time). Bets will be void if the match is abandoned
                prior to the completion of 90 minutes play. Singles and upwards are available.
            </p>

            <mat-card-subtitle>
                <h4><b>Both Teams To Score</b></h4>
            </mat-card-subtitle>
            <p>
                If your prediction to this bet is YES then both the teams have to score at least one goal each and if at all
            either or both teams fail to score then the result of this bet option is NO.
            </p>

            <mat-card-subtitle>
                <h4><b>First Team To Score</b></h4>
            </mat-card-subtitle>
            <p>
                You have to predict which will be the first team to score in this game. All bets apply to the 90 minutes of
                play plus injury time. Extra time and Penalty shoot outs dont count for this bet.
            </p>

            <mat-card-subtitle>
                <h4><b>Highest Scoring Half</b></h4>
            </mat-card-subtitle>
            <p>
                In which half will the most goals be scored. This market applies only to the full time play. (90 minutes +
                injury time)
            </p>

            <mat-card-subtitle>
                <h4><b>Draw No Bet</b></h4>
            </mat-card-subtitle>
            <p>
                IF THIS MATCH IS A DRAW, ALL BETS WILL BE VOID. All bets apply to 90 minutes of play according to the match
            officials, plus any stoppage time. Extra-time/penalty shoot-outs are not included.
            </p>

            <mat-card-subtitle>
                <h4><b>Cup Win</b></h4>
            </mat-card-subtitle>
            <p>
                Predict which team will win this competition?
            </p>
            <p>
                At the start of the competition all unmatched bets will be cancelled and this market will be re-opened. And
            the markets will be actively managed before the start of all the games.
            </p>

            <mat-card-subtitle>
                <h4><b>Multi Goal</b></h4>
            </mat-card-subtitle>
            <p>
                Bets are settled on the total number of goals to be scored during 90 minutes play.

            </p>
        </div>
    </section>
</app-nav>