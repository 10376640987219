<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>
                    Customer Responsibility
                </h1>
            </mat-card-title>
            <p>      
                KAGWIRAWO customers should make themselves aware of all of the KAGWIRAWO market rules affecting any market on which they wish to place a bet. Customers cannot simply rely on the Market Information as the Market Information is unlikely to contain all of the applicable rules relating to a market. In any dispute, KAGWIRAWO reserves all the rights to take any decision pertaining to settling bets on events/sports/games depending as per the rules and regulations laid by KAGWIRAWO.
            </p>
        </div>
    </section>
</app-nav>