import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BettingOptionsComponent } from './betting-options/betting-options.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FaqsComponent } from './faqs/faqs.component';
import { HowToComponent } from './how-to/how-to.component';
import { HowToMobileComponent } from './how-to-mobile/how-to-mobile.component';
import { PayoutCalculationComponent } from './payout-calculation/payout-calculation.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ResponsibleGamblingComponent } from './responsible-gambling/responsible-gambling.component';
import { TermsComponent } from './terms/terms.component';
import { VideosComponent } from './videos/videos.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { K3Component } from './k3/k3.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { StoriesComponent } from './stories/stories.component';
import { VirtualsComponent } from './virtuals/virtuals.component';

const routes: Routes = [
  {
    path:'',
    redirectTo:'about',
    pathMatch:'full'
  },
  {path: 'about', component: AboutComponent},
  {path:'betting-options', component: BettingOptionsComponent},
  {path:'contact-us', component:ContactUsComponent},
  {path:'disclaimer', component:DisclaimerComponent},
  {path:'faqs', component:FaqsComponent},
  {path:'how-to-bet', component:HowToComponent},
  {path:'how-to-bet-mobile', component:HowToMobileComponent},
  {path:'payout-calculation', component:PayoutCalculationComponent},
  {path:'privacy', component:PrivacyComponent},
  {path:'responsible-gambling', component:ResponsibleGamblingComponent},
  {path:'terms', component:TermsComponent},
  {path:'videos', component:VideosComponent},
  {path:'jackpot', component:JackpotComponent},
  {path:'k3', component:K3Component},
  {path: 'promotions', component:PromotionsComponent},
  {path: 'stories', component:StoriesComponent},
  {path: 'virtuals', component:VirtualsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
