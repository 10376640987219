<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>How to Bet</h1>
            </mat-card-title>
            <mat-card-subtitle>
                <h4>Step 1: Log into Kagwirawo</h4>
            </mat-card-subtitle>
            <p>
                You need to be logged into the Kagwirawo website before you can Bet. Registrations for new visitors is a
                simple process that requires your email and phone number.
            </p>
            <img src="../../assets/img/Kag-login.png" alt="" width="100%">
 
            <mat-card-subtitle>
                <h4>Step 2: Start Betting</h4>
            </mat-card-subtitle>
            <p>
                Once logged in, you'll be able to place your bets on any of the fixtures presented. You'll have to place
            your bets on ATLEAST 2 games. With every bet you make, you can see it in the result slip section to the
            right of the fixtures.
            </p>
            <img src="../../assets/img/Kag-steps.jpg" alt="" width="100%">

            <mat-card-subtitle>
                <h4>Step 3: Enter your Stake</h4>
            </mat-card-subtitle>
            <p>
                Enter your stake. Your phone number, would be listed with the your Kagwirawo account Balance. Its from this
            money that you add to your stake.
            </p>
 
            <mat-card-subtitle>
                <h4>Step 4: Submit your Slip</h4>
            </mat-card-subtitle>
            <p>
                After making the bets and entering your stake, you can now submit your betting slip.
            </p>

            <mat-card-subtitle>
                <h4>Step 5: Your Receipt</h4>
            </mat-card-subtitle>
            <p>
                After submitting your Betting slip in the step above, you'll be taken to view your reciept. You'll also get
            an SMS confirming the same.
            </p>
            <img src="../../assets/img/kag-receipt.jpeg" alt="" width="100%">
        </div>
    </section>
</app-nav>