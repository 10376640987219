<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>Terms & Conditions</h1>
            </mat-card-title>
            <p>
                These are our terms and conditions and betting rules that govern the way we do business,
                how we take and settle bets, and identify what you can expect from KAGWIRAWO.
            </p>
            <p>
                This page tells you the terms and conditions on which you may make bets. Please read these terms carefully before you start to use our services. 
                By using our services, regardless of how you access them, you confirm that you accept these terms and that you agree to abide by them. 
                If you do not agree to these terms of use, please refrain from using our services.
            </p>

            <mat-card-subtitle>
                <h4>Definitions</h4>
            </mat-card-subtitle>
            <p>
                In these rules the following words have the following meanings unless the context requires otherwise
            </p>

            <ul>
                <li>We, Us, Our means KAGWIRAWO, registered offices Plot 249 Ggaba Rd Kampala.</li>
                <li>Bet means the staking of money on the prediction of the outcome of a sporting event offered by us. A
                    bet can include one or more predictions. A bet on one outcome is a single. A bet on many different
                    outcomes is a multiple.
                </li>
                <li>Odds or Price means the return on the money staked should the prediction recorded in a Bet be
                    correct. The prices paid are those at the time the bet is placed and no other.
                </li>
                <li>Customer means anyone placing a bet with KAGWIRAWO
                </li>
                <li>User means anyone using this website
                </li>
                <li>VOID means that for one reason or another the bet is not acceptable to KAGWIRAWO.
                </li>
            </ul>

            <mat-card-subtitle>
                <h4>Basics</h4>
            </mat-card-subtitle>
            <ul>

                <li>You can bet as many times as you wish</li>
                <li>Bets close at kick off unless otherwise indicated.</li>
                <li>Bets apply to the entire 90 minutes, injury time and extra time. The Penalty shoot out will only be
                    considered in perfect score category.
                </li>
                <li>The cost and the rewards are all on your KAGWIRAWO account immediately, or optionally, your
                    MobileMoney account.
                </li>
            </ul>

            <mat-card-subtitle>
                <h4>Licencing and Regulatory Authority</h4>
            </mat-card-subtitle>
            <p>
                KAGWIRAWO is operate and run under license and regulation from the Lotteries and Gaming Regulatory Board (LGRB) of Uganda, under Ministry of Finance, 
                Planning and Economic Development.
            </p>

            <mat-card-subtitle>
                <h4>How do I get my winnings?</h4>
            </mat-card-subtitle>
            <p>
                The stakes are taken off your KAGWIRAWO (or mobile money) account. Your KAGWIRAWO account is directly
                credited with your winnings.
            </p>

            <mat-card-subtitle>
                <h4>Fixtures</h4>
            </mat-card-subtitle>
            <p>
                The fixtures will be accessed after dialling the designated USSD short code e.g. *165# (on MTN), or *185# (on Airtel); 
                or directly through this website or mobile app.
            </p>

            <mat-card-subtitle>
                <h4>Odds</h4>
            </mat-card-subtitle>
            <p>
                KAGWIRAWO only offers fixed-odds betting. The odds on offer at the time you place your bet do not change, except in cases where errors have been detected,
                KAGWIRAWO reserves the right to make adjustments to the odds so as reflect what available in the market
            </p>

            <mat-card-subtitle>
                <h4>Identity of Customers</h4>
            </mat-card-subtitle>
            <p>
                Your mobile phone number is your identity. The identity of customers is determined by their mobile phone
                number. If transactions are made via your phone by someone who is not the registered user, the
                registered user will remain liable for their transactions. In addition we cannot do account transactions
                for accounts which are not identical to the phone number. This means that you cannot, for example, ask
                to be paid out on a different number other than the number you have placed a bet with.
            </p>

            <mat-card-subtitle>
                <h4>Age Restricted</h4>
            </mat-card-subtitle>
            <p>
                The Law prohibits under 25 year olds from betting. We may at any time ask you to confirm your age. You
                indemnify us of any damages if you deceive us or misrepresent your age. Underage clients will have their
                accounts closed immediately and any balances forfeit. We may also suspend ANY account for any reason and
                ask you to come with ID to one of our offices or agents to prove age, and identity.
            </p>

            <mat-card-subtitle>
                <h4>Account Security</h4>
            </mat-card-subtitle>
            <p>
                Customers are the registered user of their mobile number. Customers are responsible for the security of
                their phone numbers and their mobile money accounts. In the event that an unauthorized person uses a
                customer's phone for betting or money transactions to us, the registered user remains responsible.
            </p>

            <mat-card-subtitle>
                <h4>Pricing</h4>
            </mat-card-subtitle>
            <p>
                Customers will incur charges when using Mobile Money services. These are determined by the networks. The
                minimum stake for each game will be UGX 500. Cost of withdrawal from your KAGWIRAWO account is UGX
                500.
            </p>

            <mat-card-subtitle>
                <h4>Acceptance and Validation of Bets</h4>
            </mat-card-subtitle>
            <p>
                All football bets will be settled on 90 minutes play (also referred to as Full-time and normal time).
                This denotes the period of play, which includes time added by the Referee for injuries and other
                stoppages. This does not include scheduled extra time, or Penalty shoot-outs, if played.
            </p>
            <p>
                In matches where penalty shoot outs or extra time are due to take place, all bets are settled on 90
                minutes unless an Outright price is specifically requested and confirmed at the time the bet is placed.
            </p>
            <p>
                For matches played at neutral venues, the team listed on the left are still classed as the 'home' team
                for settlement purposes.
            </p>

            <mat-card-subtitle>
                <h4>Late bets</h4>
            </mat-card-subtitle>
            <p>
                Bets can only be placed on the outcome of a future event, unless we are offering special 'betting
                in-running prices' for that event. If KAGWIRAWO mistakenly accepts a bet for an event that has already
                started then the bet will be void and your stake returned, except in the following circumstances:
            </p>
            <ul>
                <li>Nothing has happened which would significantly alter the chances of the selection winning or losing.
                </li>
            </ul>
            <p>
                All bets placed after the result of an event is known will be void.
            </p>

            <mat-card-subtitle>
                <h4>Cancellation of Bets</h4>
            </mat-card-subtitle>
            <p>You cannot cancel a bet after it has been accepted.</p>

            <mat-card-subtitle>
                <h4>Account Corrections</h4>
            </mat-card-subtitle>
            <p>
                KAGWIRAWO has the right to recover from you any amount overpaid and has your authority to adjust your account to reflect the true outcome and rectify the mistake. An example of such a mistake might be where a price is incorrect, a bet is late or where we enter a result of an event incorrectly.
                In the event that you have already withdrawn the money from your KAGWIRAWO account, you hereby agree to return all the funds so erroneously withdrawn, failing which KAGWIRAWO reserves the right to compel you through the police and courts of law to return these funds.
            </p>

            <mat-card-subtitle>
                <h4>Disputes</h4>
            </mat-card-subtitle>
            <p>
                If you wish to make a query or complaint, you should call our customer care desk on 0761345340/0702215201 or email us at info@kagwirawo.co.ug, for assistance. If, notwithstanding the above, the customer issues Court proceedings, KAGWIRAWO reserve the right to apply to stay those proceedings pending determination by an independent body.
            </p>
            <p>
                Disputes MUST be tendered in writing, within 180 days from the date of occurance.
            </p>

            <mat-card-subtitle>
                <h4>Errors</h4>
            </mat-card-subtitle>
            <p>
                KAGWIRAWO will make every effort to ensure that we do not make errors in accepting bets. However, if as
                a result of human error or system problems a bet is accepted at a price (which includes the odds,
                handicap provisions, and other terms and conditions or details of the bet) that is:
            </p>
            <ul>
                <li>Materially different from those available in the general market at the time the bet was made; or
                </li>
                <li>Clearly incorrect given the chance of the event occurring at the time the bet was made then
                    KAGWIRAWO will pay winnings at the correct price.
                </li>
            </ul>
            <p>
                To establish the correct price KAGWIRAWO will consider prices available in the general market at the
                time the bet was made, including the prices offered by other bookmakers operating in the same market.
            </p>

            <p>Examples of circumstances where this would apply are:</p>
            <ul>
                <li>The price is recorded as 100-1 when the price on offer in the general market is 10-1</li>
                <li>The margins for handicap betting have been reversed</li>
            </ul>
            <p>
                If a bet is accepted in error by KAGWIRAWO on an event or outcome for which no KAGWIRAWO prices are
                available the bet will be void and your stake returned.
            </p>
            <p>
                A bet accepted on this website or via sms does not constitute a legally binding contract between the
                customer and us. The prices we offer on all outcomes on display on this website take the form of an
                advertisement rather than a contractual term. If we offer mistaken prices, then we are entitled to
                decline any bet placed at those prices at any time. In such instances we will void the bet and return
                the stake money.
            </p>
            <p>
                Any results or scores displayed on the site – for example during betting in play – are for guidance
                purposes only.
            </p>

            <mat-card-subtitle>
                <h4>Fraud</h4>
            </mat-card-subtitle>
            <p>
                KAGWIRAWO will seek criminal and contractual sanctions against any customer involved in fraud,
                dishonesty or criminal acts. KAGWIRAWO will withhold payment to any customer where any of these are
                suspected.
            </p>
            <p>
                The customer shall indemnify and shall be liable to pay to KAGWIRAWO, on demand, all costs, charges or
                losses sustained or incurred by KAGWIRAWO (including any direct, indirect or consequential losses, loss
                of profit and loss of reputation) arising directly or indirectly from the customer’s fraud, dishonesty
                or criminal act.
            </p>

            <mat-card-subtitle>
                <h4>Price Changes</h4>
            </mat-card-subtitle>
            <p>
                All prices are subject to change and may, on occasion, be restricted to certain stake levels. The prices
                offered via our different business channels may vary. KAGWIRAWO reserves the right to make the price
                adjustments without recourse to the customer.
            </p>

            <mat-card-subtitle>
                <h4>Results</h4>
            </mat-card-subtitle>
            <p>
                In the case of Football and other Sports, bets are settled on the official result published by the Press
                Association (PA), immediately after the match/event is finished. If the PA amends its decision after
                this period, all bets will stand on their original result.
            </p>

            <mat-card-subtitle>
                <h4>Voids</h4>
            </mat-card-subtitle>
            <p>
                Void selections in multiple/accumulator bets will be treated as non-runners and the stake will run onto
                the remaining selections in the bet.
            </p>

            <mat-card-subtitle>
                <h4>Witholding payment and our right to set off liability</h4>
            </mat-card-subtitle>
            <p>
                We reserve the right to withhold payment and to declare bets on an event void if we have evidence that
                the following has occurred:
            </p>
            <ul>
                <li>The integrity of the event has been called into question</li>
                <li>The price(s) or pool has been manipulated</li>
                <li>Match rigging has taken place</li>
            </ul>
            <p>
                Evidence of the above may be based on the size, volume or pattern of bets placed with us across any or
                all of our betting channels. A decision given by the relevant governing body of the sport in question
                (if any) will be conclusive. If any customer owes any money to KAGWIRAWO for any reason, we have the
                right to take that into account before making any payments to that customer.
            </p>

            <mat-card-subtitle>
                <h4>90-minute Football Bets</h4>
            </mat-card-subtitle>
            <p>
                What is meant by '90-minute Betting', 'Full Time' and 'Normal Time'?
            </p>
            <p>
                Adult football matches are played over a 90 minute period, and this period can be called '90-minutes',
                'Full Time' or 'Normal Time'. All football bets, unless specifically stating they are for Outright, To
                Qualify, Extra Time or Penalties markets, will be settled on the result after Normal Time (90 minutes).
            </p>
            <p>
                During the game, the referee may stop the game for short periods for substitutions, or if a player gets
                injured, and at the end of each 45 minute half the referee adds however many minutes he feels is
                required to account for any of these stoppages. This additional time is called "Injury time".
            </p>
            <p>
                Full Time and Normal Time are terms for the full 90-minute period, plus any 'Injury Time' that the
                referee chooses to add. Full Time and Normal Time DO NOT include Extra Time.
            </p>

            <mat-card-subtitle>
                <h4>Abandoned Matches</h4>
            </mat-card-subtitle>
            <p>
                If a match is abandoned after it has commenced, all bets on that match will be made void except where
                settlement has already been determined. For example, where the first goal has been scored by a named
                player the First Goalscorer and Time of First Goal markets, amongst others, will stand.
            </p>

            <mat-card-subtitle>
                <h4>Postponed/re-arranged matches</h4>
            </mat-card-subtitle>
            <p>
                A postponed match is void unless it is re-scheduled to commence within 24 hours of the original start
                time and this is confirmed within 12 hours of the original start time. In such circumstances where (a)
                void match(es) is/are included in an accumulator the bet will be settled on the remaining selections.
            </p>

            <mat-card-subtitle>
                <h4>Prices subject to fluctuation</h4>
            </mat-card-subtitle>
            <p>
                All prices are subject to fluctuation up to the kick-off. All football bets will be settled using
                KAGWIRAWO online prices at the time the bet is placed.
            </p>

            <mat-card-subtitle>
                <h4>Related contingencies in one match</h4>
            </mat-card-subtitle>
            <p>
                Where we offer various betting opportunities from the same match (e.g. correct score, first player to
                score etc) these cannot be combined in accumulative bets where the outcome is related (except where
                special fixed prices are available).
            </p>
            <p>
                Where an accumulative bet of this type as been accepted in error it will be settled by equally dividing
                the stake unit where the related outcomes clash. EXAMPLE: A 2000 UShs Double:- Team A to win in 90
                minutes play and Team A to win 2-1 is settled as 2 x 1000 UShs singles.
            </p>

            <mat-card-subtitle>
                <h4>Results</h4>
            </mat-card-subtitle>
            <p>
                In the case of football and other sports, bets are settled on the official result published by KAGWIRAWO
                immediately the match/event is finished will be used for settling purposes. Any subsequent corrections
                or amendments to this result will not apply for settlement purposes. KAGWIRAWO will settle all bets
                rapidly but because of time differences, for some events, bets may be settled overnight or with some
                delay.
            </p>

            <mat-card-subtitle>
                <h4>Limits</h4>
            </mat-card-subtitle>
            <p>
                Unless otherwise authorised by KAGWIRAWO the maximum payout of any winning bet shall be UGX 20,000,000.(
                Uganda Shillings Twenty Million Only)
            </p>
            <ul>
                <li>Minimum deposit amount: UGX 500</li>
                <li>Minimum withdrawal amount: UGX 500</li>
                <li>Minimum stake: UGX 500</li>
                <!-- <li>Maximum stake: UGX 1,000,000</li> -->
                <li>Maximum winnings (per bet): UGX 20,000,000</li>
                <li>Maximum withdrawal (per day): UGX 1,000,000</li>
            </ul>

            <mat-card-subtitle>
                <h4>Bonus Rules</h4>
            </mat-card-subtitle>
            
             <p>
               • The maximum payout of any winning bet placed using a bonus shall be UGX5,000.
            </p>
            <p>
                • Account bonus(es) cannot be used to place jackpot bets.
             </p> 
             <p>
                • Users may be eligible to participate in bonus programs, which conditions, amounts and releases will be altered, from time to time, at the discretion of Kagwirawo and are non-negotiable
             </p>
             <p>
                • If you are eligible for a bonus, you'll be notified through any one of the channels: SMS, USSD, email or website (kagwirawo.co.ug).
             </p>
             <p>
                • Some of our bonus offers and promotions are available for a limited time, and if you don't claim the offer within its time-frame, it will no longer be available to you and  will disappear from your account. For those offers that do not expire, Kagwirawo reserves the right to cancel and remove any bonuses awarded to you, if such bonuses have not been used within 1 month from the date they were added.
             </p>
             <p>
                • In order to qualify for bonus, you have to use your real money balance to wager. You can also qualify for and receive bonus from Kagwirawo based on events or conditions at as we may, at our sole discretion, determine from time to time e.g. bonus on registration. Your bonus funds can only be used for betting, however, any winnings from such bets become real money and can therefore be withdrawn.
             </p>

            <mat-card-subtitle>
                <h4>Unmatched bets</h4>
            </mat-card-subtitle>
            <p>
                At kick off unmatched bets will be automatically cancelled and only those bets will be accepted for
                which the confirmation was shown after confirming the bet and the stake has been deducted from your
                account. Therefore, after placing the bet, please confirm it and then wait for the confirmation message
                on screen and then cross verify your account for more assurance of the acceptance of the bet.
            </p>

            <mat-card-subtitle>
                <h4>Time Of Acceptance Of Bets</h4>
            </mat-card-subtitle>
            <p>
                All bets will be accepted until kick off of the game. If, for any reason the bet is inadvertently
                accepted, the selection will be void. In the event of dispute over the time of the placement of bet,
                then the time which was recorded at the placement of bet (recorded time in the website) will be used to
                govern. When you make a bet, you are betting with KAGWIRAWO and not with other customers who might use
                our site.
            </p>
            <p>
                An offer which you make for a bet is open for acceptance by KAGWIRAWO as a principal counterparty in
                whole or in part in accordance with these terms and conditions.
            </p>
            <p>
                Once KAGWIRAWO has accepted your offer, you will not be allowed to change the terms of that bet or
                cancel or withdraw from that bet. Acceptance of an offer by KAGWIRAWO concludes the bet.
            </p>

            <mat-card-subtitle>
                <h4>Termination of Account</h4>
            </mat-card-subtitle>
            <p>
                Kagwirawo reserves the right to cancel your account for any reason whatsoever at any time without
                notifying you. Any balance in your account at the time of such a cancellation will be credited to your
                credit/debit card. We also reserve the right, at our sole discretion, to void any winnings and
                confiscate any balance in your Kagwirawo account in any of the following circumstances:
            </p>
            <ul>
                <li>If you have more than one active account at Kagwirawo</li>
                <li>If you use our Website, Software or your account in bad faith</li>
                <li>If you are not of legal age to gamble</li>
            </ul>

            <mat-card-subtitle>
                <h4>Publicity</h4>
            </mat-card-subtitle>
            <p>
                By accepting any prize and/or winnings from Kagwirawo, you agree to being contacted and asked to consent
                for your name to be used for advertising and promotional purposes without additional compensation except
                where prohibited by law.
            </p>

            <mat-card-subtitle>
                <h4>Taxes</h4>
            </mat-card-subtitle>
            <p>
                You are solely responsible for any applicable taxes on any prizes and/or winnings that you collect from
                Kagwirawo.
            </p>

            <mat-card-subtitle>
                <h4>Force Majeure</h4>
            </mat-card-subtitle>
            <p>
                Kagwirawo shall not be liable or responsible for any failure to perform, or delay in performance of, any
                of our obligations under the gaming contract that is caused by events outside our reasonable control.
            </p>

            <mat-card-subtitle>
                <h4>Service Suspension</h4>
            </mat-card-subtitle>
            <p>
                We may temporarily suspend the whole or any part of the Service for any reason at our sole discretion.
                We may, but shall not be obliged to, give you as much notice as is reasonably practicable of such
                suspension. We will restore the Service, as soon as is reasonably practicable, after such temporary
                suspension.
            </p>

            <mat-card-subtitle>
                <h4>Malfunctions</h4>
            </mat-card-subtitle>
            <p>
                We shall not be liable for computer malfunctions, failure of telecommunications service or Internet
                connections nor attempts by you to Participate in games by methods, means or ways not intended by us.
            </p>

            <mat-card-subtitle>
                <h4>Viruses</h4>
            </mat-card-subtitle>
            <p>
                Although we shall take all reasonable measures to ensure that the Software and files are free from
                computer viruses we cannot and do not guarantee that the Software and files are free of such problems.
                It is your responsibility to protect your systems and have in place the ability to reinstall any data or
                programs lost due to a virus
            </p>

            <mat-card-subtitle>
                <h4>No liability</h4>
            </mat-card-subtitle>
            <p>
                Kagwirawo shall accept no liability for any damages or losses which are deemed or alleged to have arisen
                out of or in connection with your Participation; including and without limitation, delays or
                interruptions in operation or transmission, loss or corruption of data, communication or lines failure,
                any person’s misuse of the sportsbook, its content or any errors or omissions in the Website’s content.
                Kagwirawo reserves the right to close or suspend your account.
            </p>

            <mat-card-subtitle>
                <h4>Dormant/Inactive Accounts</h4>
            </mat-card-subtitle>
            <p>
                Your account will be considered dormant if it has not had any activity for 18 months. Account activity
                is defined as the following: making of a deposit or withdrawal; placement of a bet.
            </p>

            <mat-card-subtitle>
                <h4>Risk</h4>
            </mat-card-subtitle>
            <p>
                You accept that Participation with Kagwirawo is at your sole option, discretion and risk. Participation
                with Kagwirawo is for your own personal entertainment and non-professional use and, in doing so, are
                acting on your own behalf and acknowledge that any activities outside the intended use are strictly
                prohibited.
            </p>

            <mat-card-subtitle>
                <h4>Credit</h4>
            </mat-card-subtitle>
            <p>
                Kagwirawo does not offer credit.
            </p>
            <br>
            <mat-card-subtitle>
                <h4>Kagwirawo JackPot</h4>
            </mat-card-subtitle>
            <br>
            <mat-card-subtitle>
                <h4>Terms & Conditions</h4>
            </mat-card-subtitle>
        
            <p>1. All event settlements are based on the result at the end of regular time. This
                includes any added stoppage time but does not include extra time and penalty
                shoot-out.</p>
                
            <p>2. Predictions are open until the kick off time of the first event.</p>
                
            <p>3. In case an event is postponed/abandoned beyond 24 hours, then we may cancel it. A
                cancelled event is   automatically considered as a correct prediction on the
                bettor's receipt.</p>
                
            <p>4. If 3 or more events are cancelled in a 'Pick 13' Jackpot, or 2 or more events are
                cancelled in a 'Pick 7' Jackpot, then all the receipts on that Jackpot are voided
                and the participants' stakes are refunded.</p>
                
            <p>5. All winnings will be paid out after the last event on the ticket has finished and
                all results have been verified.</p>
                
            <p>6. All entries must be submitted before the advertised Jackpot closure time. Any
                entries after will be declared void with stakes refunded</p>
                
            <p>7. A Player must select correctly all the outcomes for all events in the Jackpot to
                win a share of the  Jackpot payout</p>
                
            <p>8. If there is more than one winner then the Jackpot payout will be shared equally
                among all the winners.</p>
                
            <p>9. There will be consolation prizes for the different jackpots. KAGWIRAWO reserves
                the right to determine, from time to time how much these consolation prizes will be
                and for how many correct predictions they will be awarded.</p>
                
            <p>10. KAGWIRAWO reserves the right to reverse the settlement of a event if it has been
                settled in error</p>

        </div>
    </section>
</app-nav>