<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>
                    Payout Calculation
                </h1>
                <mat-divider></mat-divider>
                <br>
            </mat-card-title>

            <mat-card-subtitle>
                <h4>For Single Bets</h4>
            </mat-card-subtitle>
            <p>
                Winning Amount = Your stake multiplied by the odds.
                For example if there is a bet of UGX 100 on Manchester United (Odds 1.6) So UGX 100 (Stake) x 1.6
                (Odds)=UGX 160 (Winning Payout)
            </p>
            <p style="text-align: center;"><b>OR</b></p>

            <p>
                if there is a bet of 1,000 UGX on Manchester United (Odds 1.6) So UGX 1,000 (Stake) x 1.6 (Odds)=UGX
                1,600 Winning Payout.
            </p>
            <p style="text-align: center;"><b>OR</b></p>

            <p>
                if there is a bet of 1,500 UGX on Manchester United (Odds 1.6) So UGX 1,500 (Stake) x 1.6 (Odds)=UGX
                2,400 Winning Payout.
            </p>

            <mat-divider></mat-divider>
            <mat-card-subtitle>
                <h4>For Multiples(Jackpot) Bets:</h4>
            </mat-card-subtitle>
            <p>
                Winning amount=Your stake multiplied by the odds of 1st selection multiplied by the odds of second
                selection multiplied by the odds of third selection.
            </p>
            <p>
                For example if there is a Jackpot bet of UGX 100 on Manchester United, Arsenal, Chelsea, Manchester City
                & Liverpool. So the winning calculation will be done as UGX 100 (stake) x Manchester United(1.6) x
                Arsenal(1.8) x Chelsea(1.4) x Manchester City(1.9) x Liverpool(2.1)=UGX 1,608.
            </p>

            <p style="text-align: center;"><b>OR</b></p>

            <p>
                if there is a Jackpot bet of UGX 1,000 on Manchester United, Arsenal, Chelsea, Manchester City &
                Liverpool. So the winning calculation will be done as UGX 1,000 (Stake) x Manchester United (1.6) x
                Arsenal (1.8) x Chelsea(1.4) x Manchester City(1.9) x Liverpool(2.1)=UGX 16,100
            </p>
            <p>
                if there is a Jackpot bet of UGX 1,500 on Manchester United, Arsenal, Chelsea, Manchester City &
                Liverpool. So the winning calculation will be done as UGX 1,500 (Stake) x Manchester United(1.6) x
                Arsenal(1.8) x Chelsea(1.4) x Manchester City(1.9) x Liverpool(2.1)=UGX 24,131.5
            </p>
            <p>
                There can be a maximum of Twelve selection in one Jackpot betting coupon. Only one option can be
                selected from one game for Jackpot bet purpose. There will be a standard deduction of 2% from your
                winnings, which is service commission. 2% of your winning will be deducted and the remaining winnings
                will be credited to your account.
            </p>
            <p>
                Any winnings above 5 million UGX are subject to verification of bets Placed. If any problem found in
                those bets in question the winnings will be withheld.
            </p>

            <mat-divider></mat-divider>
            <mat-card-subtitle>
                <h4>Odds Fluctuating</h4>
            </mat-card-subtitle>
            <p>
                For all the odds on all sports/games/events are subject to fluctuation and amendment. It may happen that
                you see some particular odd for some team while viewing, and when you place your bet you get some
                different odd, that is because this is an international market and it keeps updating in order to give
                you the true picture of that particular game. Generally this will happen only when a long time is taken
                to place the bet.
            </p>
            <p>
                KAGWIRAWO reserves the right to refuse a part or whole bet and correction of odds.
            </p>
        </div>
    </section>
</app-nav>