<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>Frequently Asked Questions</h1>
                <mat-divider></mat-divider>
                <br>
            </mat-card-title>
            <div>
                <mat-card-subtitle>
                    <h4>How does Kagwirawo work?</h4>
                </mat-card-subtitle>
                <p> Log into <a href="https://kagwirawo.co.ug/">kagwirawo</a></p>
                <ul>
                  <li>Go to sign up and register</li>
                  <li>Deposit money</li>
                  <li>Select games</li>
                  <li>Place your stake</li>
                </ul>

                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>What taxes are paid?</h4>
                </mat-card-subtitle>
                <p>The Uganda Revenue Authority imposes a Withholding Tax (WHT) of 15% on customer winnings. For example: if your total 
                    winnings are UGX 10,000; Kagwirawo will pay into you wallet UGX 8,500 and retain (UGX 1,500) as tax, to be remitted to 
                    Uganda Revenue Authority.
                </p>

                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>How much can i stake on a bet?</h4>
                </mat-card-subtitle>
                <p>Minimum stake is UGX 1,000 (One Thousand Uganda Shillings Only). The more you stake, the higher your winnings will be.</p>
      
                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>What is the maximum payout?</h4>
                </mat-card-subtitle>
                <p>Our maximum payout is UGX 20,000,0000 (Twenty Million Uganda Shillings only) per bet.</p>
      
                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>How much can I withdraw in a day?</h4>
                </mat-card-subtitle>
                <p>By default, you can withdraw a maximum of UGX 2,000,000 (Two Million Uganda Shilling) per day.
                    This is for your own protection in case your account gets compromised, your money is safe with Kagwirawo.
                    If you want to be able to adjust your withdrawal limit, please to get in touch with us and we shall adjust 
                    it accordingly.
                </p>
      
                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>How do i reset my password?</h4>
                </mat-card-subtitle>
                <p>Follow the 'Reset Password' link from the top of the page just below the login</p>
                <ul>
                  <li>Enter you email address or phone number</li>
                  <li>Click then "Reset password" button.</li>
                  <li>Check your inbox (email or SMS), depending on the account ID you choose to use for password retrieval</li>
                </ul>

                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>How do I deposit money?</h4>
                </mat-card-subtitle>
                <b>MTN Uganda:</b>
                <ul>
                  <li>Log in to your Kagwirawo account</li>
                  <li>Go to; "My Account" -> "Deposit"</li>
                  <li>Enter the amount and reason and click the "Deposit" button</li>
                  <li>Then; dial *165# and to to "8) My Account"; select "2) My Approvals"</li>
                  <li>Enter your PIN, then select the payment from the list of pending payments</li>
                  <li>Approve the payment</li>
                </ul>
                <em>Your money will instantly reflect on your account and you can go ahead and bet with your Kagwirawo balance.</em>
                <br><br>

                <b>Airtel Uganda</b>
                <ul>
                  <li>Dial *185#</li>
                  <li>Select "5. Payments"</li>
                  <li>Select "3. BETTING and GAMING"</li>
                  <li>Select "1. Kagwirawo"</li>
                  <li>Select "2. Deposit"</li>
                  <li>Enter the amount you wish to deposit into your Kagwirawo account.</li>
                  <li>Enter the reason for your deposit.</li>
                  <li>Approve the payment by entering your PIN.</li>
                </ul>
                <em>Your money will instantly reflect on your account and you can go ahead and bet with your Kagwirawo balance.</em>
      
                <mat-divider></mat-divider>
                <mat-card-subtitle>
                    <h4>How do I withdraw money?</h4>
                </mat-card-subtitle>
                <ul>
                  <li>Log in to your Kagwirawo account</li>
                  <li>Go to; "My Account" -> "Withdraw"</li>
                  <li>Enter the amount and reason and click the "Withdraw" button</li>
                </ul>
                <p>Your money will instantly reflect on your MTN/Airtel MobileMoney account. 
                    You'll get 2 confirmation SMSes of the transaction. 
                    You can go ahead and withdraw cash from any MTN/Airtel MobileMoney agent.
                </p>

                <br>
                <mat-divider></mat-divider>
                <br>
                
                <mat-card-subtitle>
                    <h4>Kagwirawo JackPot</h4>
                </mat-card-subtitle>

                <mat-divider></mat-divider>


                <mat-card-subtitle>
                    <h4>Frequently Asked Questions</h4>
                </mat-card-subtitle>

                <mat-card-subtitle>
                    <h4> 1.What is A JackPot?</h4>
                </mat-card-subtitle>
                <p>Jackpot is a pool of games where punters bet on pre-selected football matches through out the week.  </p>

                <mat-card-subtitle>
                    <h4> 2.Type of Jackpots?</h4>
                </mat-card-subtitle>
                <p> • Pick 13 at a stake of 200 (5,000,000 Jackpot)</p>
                <p>
                    In this type of Jackpot a Player must select correctly all the outcomes for all 13 matches to win/share the 5,000,000 UGX. There are also cash winnings for 13 and 12 correct predictions. The prizes may vary in size from week to week. The exact amounts will be disclosed once the pool has been settled. Please note that in case there is more than 1 winner, prize pool will be shared
                </p>
            
            <!-- <p> • Pick 7 at a stake of 1000 (2,500,000 Jackpot)</p>
            <p>
                In this type of Jackpot a Player must select correctly all the outcomes for all 7 matches to win/share the 2,500,000 UGX. Please note that in case there is more than 1 winner, prize pool will be shared
            </p> -->
            
            <p> • Pick 7 at a stake of 500 (2,500,000 Jackpot)</p>
            <p>
                In this type of Jackpot a Player must select correctly all the outcomes for all 7 matches to win/share the 2,500,000 UGX. Please note that in case there is more than 1 winner, prize pool will be shared
            </p>
  
            <mat-card-subtitle>
                <h4>3.What is a Jackpot line in betting?</h4>
            </mat-card-subtitle>
            <p>A jackpot line is the number of times a punter wishes to select the displayed options on a particular event </p>
            
            <mat-card-subtitle>
                <h4>4.What is the minimum stake for betting on a Jackpot?</h4>
            </mat-card-subtitle>
            <p>• The minimum stake for betting on the 5 million Jackpot is 200</p>
            <p>• The minimum stake for betting on the 2.5 million Jackpot is 500</p>
            <!-- <p>• The minimum stake for betting on the 2.5 million Jackpot is 1000</p> -->
            </div>
        </div>
    </section>
</app-nav>