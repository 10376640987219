<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <h3>Contact Details</h3>
            <div class="number-div" fxLayout="row">
                <div class="airtel-div" fxLayout="row">
                    <img alt class="airtel-icon" src="../../assets/img/airtel.png">
                    <p>Airtel: +256 752 215201</p>
                </div>
                <div class="mtn-div" fxLayout="row">
                    <img alt class="mtn-icon" src="../../assets/img/mtn.png">
                    <p>MTN: +256 312 215201</p>
                </div>
            </div>
            <div class="email-facebook-div" fxLayout="row">
                <div class="email-div" fxLayout="row">
                    <img alt class="email-icon" src="../../assets/img/email.png">
                    <p style="margin-left: 0.5vw">Email: info@kagwirawo.co.ug</p>
                </div>
                <div class="fb-div" fxLayout="row">
                    <img alt class="fb-icon" src="../../assets/img/facebook.png">
                    <p>www.facebook.com/Kagwirawo/</p>
                </div>
            </div>
            <div class="visit-div" fxLayout="row">
                <img alt class="visit-icon" src="../../assets/img/location.png">
                <p>Or visit our offices at Blue Cube, Plot 249, Ggaba Road</p>
            </div>
        </div>
    </section>
</app-nav>