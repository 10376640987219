<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>Virtual Football (Soccer) Betting Rules, Bet Types & Resulting Rules</h1>
                <mat-divider></mat-divider>
                <br>
            </mat-card-title>
            <div>
                <p> Virtual Football match results are created using a probabilistic valuation
                    of an outcome occurring based on the estimated strength of the
                    participating Virtual Teams. A Random Number Generator (RNG) is used to
                    determine what the results actually occurred for each event. This
                    guarantees that the outcome from one match does not influence the next
                    match, and that results will vary, even if the same Virtual Teams play
                    each other multiple times.
                </p>

                <mat-card-title>
                    <h1>Terms and Conditions</h1>
                </mat-card-title>
                <mat-divider></mat-divider>
                
                <mat-card-subtitle>
                    <h4>Frequently Asked Questions</h4>
                </mat-card-subtitle>

                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        1.Cancelled Matches
                    </h4>
                </mat-card-subtitle>
                <p>
                    In case of unforeseen circumstances it might occur that a regularly
scheduled Virtual Match does not get to launch. In case of this happening
all bets on the match will be cancelled & stakes refunded
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        2.Minimum Stake
                    </h4>
                </mat-card-subtitle>
                <p>
                    The Minimum stake for all virtual bets is 500 Ugx
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        3.Maximum Win
                    </h4>
                </mat-card-subtitle>
                <p>
                    Unless otherwise authorized by Kagwirawo, the maximum payout of any
winning bet on virtual football shall be 20,000,000 Ugx
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        4.Late bets
                    </h4>
                </mat-card-subtitle>
                <p>
                    If KAGWIRAWO mistakenly accepts a bet for an event that has already
started then the bet will be void and your stake returned, except in the
following circumstances:

Nothing has happened which would significantly alter the chances of the
selection winning or losing.
All bets placed after the result of an event is known will be void.
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        5.Cancellation of Bets
                    </h4>
                </mat-card-subtitle>
                <p>
                    You cannot cancel a bet after it has been accepted.
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        6.Errors
                    </h4>
                </mat-card-subtitle>
                <p>
                    KAGWIRAWO will make every effort to ensure that we do not make errors in
accepting bets. However, if as a result of human error or system problems
a bet is accepted in error, then the bet will be cancelled and stakes
refunded
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        7.Fraud
                    </h4>
                </mat-card-subtitle>
                <p>
                    KAGWIRAWO will seek criminal and contractual sanctions against any
customer involved in fraud, dishonesty or criminal acts. KAGWIRAWO will
withhold payment to any customer where any of these are suspected.

The customer shall indemnify and shall be liable to pay to KAGWIRAWO, on
demand, all costs, charges or losses sustained or incurred by KAGWIRAWO
(including any direct, indirect or consequential losses, loss of profit fraud, dishonesty or criminal act.)
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        8.Price Changes
                    </h4>
                </mat-card-subtitle>
                <p>
                    All prices are subject to change and may, on occasion, be restricted to
certain stake levels. The prices offered via our different business
channels may vary. KAGWIRAWO reserves the right to make the price
adjustments without recourse to the customer.
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        9.Results
                    </h4>
                </mat-card-subtitle>
                <p>
                    In the case of Football and other Sports, bets are settled based on the
official result generated by the Random Number Generator (RNG),
immediately after the match/event is finished
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        10.Taxes
                    </h4>
                </mat-card-subtitle>
                <p>
                    All bets are subject to a 15% withholding tax. You are solely responsible
for any applicable taxes on any prizes and/or winnings that you collect
from Kagwirawo.
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>

                <mat-card-subtitle>
                    <h4>
                        10.Time Of Acceptance Of Bets
                    </h4>
                </mat-card-subtitle>
                <p>
                    All bets will be accepted until kick off of the game. If, for any reason
the bet is inadvertently accepted, the selection will be void. In the
event of dispute over the time of the placement of bet, then the time
which was recorded at the placement of bet (recorded time in the website)
will be used to govern. When you make a bet, you are betting with
KAGWIRAWO and not with other customers who might use our site.
                </p>
                <mat-divider style="margin: 8px auto;"></mat-divider>
            </div>
        </div>
    </section>
</app-nav>