import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule} from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { LayoutModule } from '@angular/cdk/layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {MatBadgeModule} from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
  	imports: [
		MatToolbarModule,
		MatButtonModule,
		MatSelectModule,
		MatFormFieldModule,
		MatInputModule,
		MatSnackBarModule,
		MatDialogModule,
		MatCardModule,
		MatSidenavModule,
		MatListModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatMenuModule,
    MatProgressBarModule,
		LayoutModule,
		MatDividerModule,
		MatBottomSheetModule,
		MatBadgeModule,
		MatCheckboxModule,
		MatExpansionModule
  	],
  	exports: [
		MatToolbarModule,
		MatButtonModule,
		MatSelectModule,
		MatFormFieldModule,
		MatInputModule,
		MatSnackBarModule,
		MatDialogModule,
		MatCardModule,
		MatSidenavModule,
		MatListModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatMenuModule,
    MatProgressBarModule,
		LayoutModule,
		MatDividerModule,
		MatBottomSheetModule,
		MatBadgeModule,
		MatCheckboxModule,
		MatExpansionModule
	],
  	declarations: []
})
export class MaterialModule { }
