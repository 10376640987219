<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>Privacy Policy</h1>
            </mat-card-title>
            <h3 class="p_effect">Effective Date: January 1, 2016.</h3>
            <p>
                This Privacy Policy describes your privacy rights regarding our collection, use, storage, sharing and protection of your personal information. (PII) It applies to the Kagwirawo website and all related sites, applications, services and tools regardless of how you access or use them. Please read our Privacy Policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
            </p>
          
            <mat-card-subtitle>
                <h4>What personal information do we collect from the people that visit our blog, website or app?</h4>
            </mat-card-subtitle>
          <p>
            When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience.
          </p>
          
          <mat-card-subtitle>
            <h4>When do we collect information?</h4>
        </mat-card-subtitle>
          <p>
            We collect information from you when you register on our site, place an order, fill out a form or enter information on our site.
          </p>
          
          <mat-card-subtitle>
            <h4>How do we use your information?</h4>
        </mat-card-subtitle>
          <p>
            We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
          </p>
          <ul>
            <li>To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
            <li>To allow us to better service you in responding to your customer service requests.</li>
            <li>To improve our website in order to better serve you.</li>
            <li>To administer a contest, promotion, survey or other site feature.</li>
            <li>To quickly process your transactions.</li>
            <li>To send periodic emails regarding your order or other products and services.</li>
          </ul>

          <mat-card-subtitle>
            <h4>How do we protect visitor information?</h4>
        </mat-card-subtitle>
        <p>
            Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.
        </p>
        <p>
            We use regular Malware Scanning.
        </p>
        <p>
            Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
        </p>
        <p>
            We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
        </p>
        <p>
            All transactions are processed through a gateway provider and are not stored or processed on our servers.
        </p>
          
          
          
          
          
          <mat-card-subtitle>
            <h4>Do we use 'cookies'?</h4>
        </mat-card-subtitle>
          <p>
            Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
          </p>
          <p>
            <b>We use cookies to:</b>
          </p>
          <ul>
            <li>Help remember and process the items in the shopping cart.</li>
            <li>Understand and save user's preferences for future visits.</li>
          </ul>
          <p>
            You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
          </p>
          
          <mat-card-subtitle>
            <h4>If users disable cookies in their browser:</h4>
        </mat-card-subtitle>
          <p>
            If you disable cookies off, some features will be disabled It will turn off some of the features that make your site experience more efficient and some of our services will not function properly.
          </p>
          
          <mat-card-subtitle>
            <h4>Third-party disclosure</h4>
        </mat-card-subtitle>
        <p>
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
        </p>
          <p>
            However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
          </p>
          
          <mat-card-subtitle>
            <h4>Third-party links</h4>
        </mat-card-subtitle>
          <p>
            Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
          </p>
          
          <mat-card-subtitle>
            <h4>Google</h4>
        </mat-card-subtitle>
          <p>
            Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en
          </p>
          <p>
            We have not enabled Google AdSense on our site but we may do so in the future.
          </p>
          
          <mat-card-subtitle>
            <h4>How does our site handle do not track signals?</h4>
        </mat-card-subtitle>
          <p>
            It's also important to note that we do not allow third-party behavioral tracking
          </p>
          
          <mat-card-subtitle>
            <h4>COPPA (Children Online Privacy Protection Act)</h4>
        </mat-card-subtitle>
          <p>
            When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.
          </p>
          <p>
            We do not specifically market to children under 18.
          </p>
          
          <mat-card-subtitle>
            <h4>Fair Information Practices</h4>
        </mat-card-subtitle>
          <p>
            The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
          </p>
          
          <mat-card-subtitle>
            <h4>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</h4>
        </mat-card-subtitle>
          <p>
            We will notify the users via email
          </p>
          <ul>
            <li>Within 1 business day</li>
          </ul>
          <p>
              We will notify the users via in-site notification
          </p>
          <ul>
            <li>Within 1 business day</li>
          </ul>
          <p>
            We also agree to the Individual Redress Principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.
          </p>
          
          <mat-card-subtitle>
            <h4>CAN SPAM Act</h4>
        </mat-card-subtitle>
        <p>
            The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
        </p>
        <p>
            We collect your email address in order to:
        </p>
        <p>
            To be in accordance with CANSPAM we agree to the following:
        </p>
        <p>
            If at any time you would like to unsubscribe from receiving future emails, you can email us at ... and we will promptly remove you from ALL correspondence.
        </p>

          <mat-card-subtitle>
            <h4>Contact US</h4>
        </mat-card-subtitle>
        <p>
            If there are any questions regarding this privacy policy you may contact us using the information below.
        </p>
          https://kagwirawo.co.ug<br>
          Plot 249, Ggaba Road<br>
          Kampala, Uganda<br>
          info@kagwirawo.co.ug<br>
          <br>
          Last Edited on 2016-02-12 
        </div>
    </section>
</app-nav>