import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { AboutComponent } from './about/about.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavComponent } from './nav/nav.component';
import { BettingOptionsComponent } from './betting-options/betting-options.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FaqsComponent } from './faqs/faqs.component';
import { HowToComponent } from './how-to/how-to.component';
import { HowToMobileComponent } from './how-to-mobile/how-to-mobile.component';
import { PayoutCalculationComponent } from './payout-calculation/payout-calculation.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ResponsibleGamblingComponent } from './responsible-gambling/responsible-gambling.component';
import { TermsComponent } from './terms/terms.component';
import { VideosComponent } from './videos/videos.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { K3Component } from './k3/k3.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { StoriesComponent } from './stories/stories.component';
import { VirtualsComponent } from './virtuals/virtuals.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    NavComponent,
    BettingOptionsComponent,
    ContactUsComponent,
    DisclaimerComponent,
    FaqsComponent,
    HowToComponent,
    HowToMobileComponent,
    PayoutCalculationComponent,
    PrivacyComponent,
    ResponsibleGamblingComponent,
    TermsComponent,
    VideosComponent,
    JackpotComponent,
    K3Component,
    PromotionsComponent,
    StoriesComponent,
    VirtualsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
