<!-- <app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>
                    Kagwirawo Stories
                </h1>
            </mat-card-title>
            <p>
                As a way of both giving back to the community and promoting  grass root football in the community, Kagwirawo has engaged in a number of community development activities such as sponsoring local football clubs in the Fufa Big League, donating football jerseys to a number of community football teams and sponsoring local musicians in a bid to elevate their singing careers.      
            </p>
            <img src="../../assets/img/st5.jpg" width="90%">
            <p>
                Among the many teams that Kagwirawo sponsored early this year was non other than  Karo Karungi who massively benefited from a 60 Million sponsorship package. 
            </p>
            <img src="" >
            <p>
                On top of the sponsorship package, the betting house handed over 200  branded jerseys to be used through out the exciting Big League season.
            </p>
            <img src="../../assets/img/st2.JPG" width="90%">
            <p>
                As a way of promoting football in schools, Kagwirawo also went big with sponsoring the now trending high school leagues.
            </p>
            <img src="../../assets/img/st3.JPG" width="90%">
            <p>
                Most popular leagues like the Jovoc league & the Mwiri League, all benefited from the mass giving programs of Kagwirawo.
            </p>
            <img src="../../assets/img/st4.JPG" width="90%">
            <p>
                To be more specific, Enturi FC from the Jovoc league was blessed to get over 100 jerseys from kagwirawo
            </p>
            <img src="../../assets/img/st1.JPG" width="90%">
        </div>
    </section>
</app-nav> -->

<app-nav>
    <section fxLayout="column">
        <div fxFlex="60" fxFlex.xs="100">
            <mat-card-title>
                <h1>Bet For Free & Win With Kagwirawo </h1>
            </mat-card-title>

            <img class="option" src="../../assets/img/KAG23 promo-05 (1).jpg" alt="" width="90%">



                <h2>Follow these 3 simple steps to get your 1k Free Bet </h2>
            <p>
                1. Select a minimum of 8 games on your receipt             </p>
            <p>
                2. Each game you select must have an odd of 1.5 & above 
            </p>
            <p>
                3.Check at the end of your receipt for a button to claim your free bet </p>

            <button class="full-width" onclick="window.location.href='https://kagwirawo.co.ug';">Place Bet NOW!</button>

        </div>
        <!-- <div fxFlex="0" fxFlexOffset.gt-xs="5" fxFlex.xs="100">
            <mat-card-title>
                <h1>Kagwirawo Egabude Promo Guidelines </h1>
            </mat-card-title>
            <p>
                1) Place a bet On any world cup game & stand a chance to win mega prizes like TVs,Phones & hard cash 
            </p>
            <p>
                2)To be eligible for the promo, place a number of bets using either the website or ussd
            </p>
            <p>
                3)Winners will be announced every week after running a live draw live on Facebook.
            </p>
            <p>
                4) The promo shall run from 23rd November 2022 until 18th December 2022
            </p>
        </div> -->
    </section>
</app-nav>