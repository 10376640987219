<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>What is responsible Gambling?</h1>
            </mat-card-title>
            <p>
                Responsible gambling means staying in control of how much time and money we spend gambling. Shared responsibility for generating awareness of the risks associated with gambling. Creating and promoting environments that prevent or minimise problem gambling.
            </p>
            <p>
                Being responsive to community concerns around gambling. Whether you are placing a bet, buying a lottery ticket or scratch-card, playing poker or bingo, or playing on a gambling machine or casino game, gambling responsibly means treating the activity as just one form of entertainment in a balanced lifestyle. Just like with other forms of entertainment, gambling is a form of expenditure, and responsible gambling means not spending more money or time than we can reasonably afford, keeping in mind all our other responsibilities in life.
            </p>
            <img src="../../assets/img/responsible.jpg" width="100%">
            <blockquote class="underage">Underage customers are not allowed to gamble. In Uganda, all minors are not supposed to engage in betting/gambling activities.</blockquote>
            <!-- <blockquote class="underage">Underage customers are not allowed to gamble. In Uganda, all people below 25 years are not supposed to engage in betting/gambling activities.</blockquote> -->
            <p>
                Fraudulent and criminal behaviour are prohibited in betting. Operators are required to implement anti-money laundering policies and procedures. This involves implementing effective know your customer processes when taking on new customers and tracking and reporting any suspicious transactions.
            </p>
            <p>
                Customer's information must be private. Protection of customer data and records against unauthorised or unnecessary disclosure is a must. Operators are required to implement policies that ensure controls and measures are in place to prevent unauthorised disclosure and use of customer information. Customer information typically relates to data such as name, address, age, telephone number and email address.
            </p>
            <p>
                Operators must ensure that payments to and from customer accounts must be conducted according to formal and documented processes in an accurate and timely manner. Operators typically ensure that customer funds are managed separately from their own accounts and that they have sufficient cleared funds to pay all player prize wins and outstanding player balances.
            </p>
            <p>
                All gaming products should be tested to ensure they are fair and random and that they adhere to the rules of that game. Testing to ensure fair gaming is increasingly carried out by independent organisations.
            </p>
            <p>
                Operators should comply with the relevant regulatory advertising codes of practice which typically ensure that advertisements are factually correct and do not target underage or vulnerable gamblers, such as players who have self-excluded themselves from gambling. It is also expected that operators should seek permission from the customer prior to engaging in direct marketing through use of the customer’s personal details.
            </p>
            <p>
                Customers should be able to freely make comments or complaints to operators and expect operators to have in place adequate processes and procedures to deal with complaints, either internally or via an independent third-party. For example, ecogra.org provides a mediation service for disputes between players and operators.
            </p>
            <p>
                Secure, safe and reliable operating environment.
            </p>
            <p>
                Operators are required to demonstrate internal controls and processes that adhere to the licensing conditions as stipulated by the regulatory jurisdiction that issues gaming and gambling licenses. Internal controls should also be implemented to ensure that all operational, payment and technical systems and processes operate securely and effectively. In addition, operators need to demonstrate adequate business continuity management procedures to ensure that operations can continue in the event of unforeseen circumstances or disasters.
            </p>
            <mat-card-title>
                <h2>Where To Get Help In Case Of Gambling Problems</h2>
            </mat-card-title>
                <ul>
                    <li><a href="https://www.africaretreat.org" >Africa Retreat Center</a></li>
                    <li><a href="http://hopeandbeyondug.org">Hope And Beyond</a></li>
                    <li><a href="https://www.ringofhopeuganda.com/rehab-center">RING OF HOPE</a></li>
                    <li><a href="https://www.salveinternational.org">Salve International</a></li>
                 </ul>

            <mat-card-title>
                <h1>
                    Customer Responsibility
                </h1>
            </mat-card-title>
            <p>      
                KAGWIRAWO customers should make themselves aware of all of the KAGWIRAWO market rules affecting any market on which they wish to place a bet. Customers cannot simply rely on the Market Information as the Market Information is unlikely to contain all of the applicable rules relating to a market. In any dispute, KAGWIRAWO reserves all the rights to take any decision pertaining to settling bets on events/sports/games depending as per the rules and regulations laid by KAGWIRAWO.
            </p>
        </div>
    </section>
</app-nav>