import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-virtuals',
  templateUrl: './virtuals.component.html',
  styleUrls: ['./virtuals.component.scss']
})
export class VirtualsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
