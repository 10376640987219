<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>K3 Terms and Conditions</h1>
            </mat-card-title>
            <p>
                1. Minimum stake is UGX 1,000
            </p>
            <p>
                2. Maximum stake is UGX 100,000
            </p>
            <!-- <p>
                3. Kagwirawo reserves the right to make available the day’s hot numbers. Hot numbers are numbers (usually 2 or more) that Kagwirawo will ensure at least one of them is included in the draw selection
            </p> -->
            <p>
                3. Draws will run every 10 minutes, but it’s the sole discretion of Kagwirawo to adjust the frequency of these draws as we deem fit from time to time
            </p>
            <p>
                4. The payments will be according to the winning types (see table below)
            </p>

            <ul>
                <li>
                    3 digits in draw order: the draw selection matches your selection exactly. For example if you select “123” and the draw selection is “123”, your stake is multiplied by 100
                </li>
                <li>
                    3 digits in random order: if the draw selection does not match your select exactly, but if the digits in the draw selection can be rearranged to match your selection exactly, then  your stake is multiplied by 25
                </li>
                <li>
                    2 digits in draw order: any two digits in your selection match 2 digits in the draw selection exactly, by position. Your stake is multiplied by 5.
                </li>
                <li>
                    1 digit in draw order: any digit in your selection exactly matches 1 digit in the draw selection by position. Your stake is multiplied by 2.
                </li>
            </ul>
            <div>
                <img src="../../assets/k3.png" width="100%">
            </div>
        </div>
    </section>
</app-nav>