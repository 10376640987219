<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav mat-elevation-z3" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false" >
    <mat-toolbar class="mat-elevation-z3">
      <span fxFlex></span>
      <span>
        <img class="auth-logo" src="../../assets/static/logo-white.png" height="40px">
      </span>
      <span fxFlex></span>
    </mat-toolbar>
    <mat-nav-list>
      <a style="color: white;" mat-list-item href="#" routerLink="/about">About Us</a>
      <!-- <a mat-list-item href="#" routerLink="/contact-us">Contact Us</a> -->
      <mat-divider></mat-divider>
      <a style="color: white;" mat-list-item href="#" routerLink="/faqs">Frequently Asked Questions</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/how-to-bet">How to Bet</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/how-to-bet-mobile">How to (Mobile)</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/terms">Terms & Conditions</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/privacy">Privacy Policy</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/betting-options">Betting Options</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/payout-calculation">Payout Calculation</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/jackpot">Kagwirawo Jackpot</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/virtuals">Virtuals Terms</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/k3">K3 Terms</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/promotions">Promotion Terms</a>
      <!-- <a mat-list-item href="#" routerLink="/videos">Videos</a> -->
      <mat-divider ></mat-divider>
      <a style="color: white;" mat-list-item href="#" routerLink="/responsible-gambling">Responsible Gambling</a>
      <a style="color: white;" mat-list-item href="#" routerLink="/stories">Stories</a>

      <!-- <a style="color: white;" mat-list-item href="#" routerLink="/disclaimer">Disclaimer</a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-elevation-z3">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span *ngIf="isHandset$ | async">      
        <img class="auth-logo" src="../../assets/static/logo-white.png" height="40px">
      </span>
    </mat-toolbar>
    <section style="padding: 16px;">
      <ng-content>
      </ng-content>
    </section>
    
  </mat-sidenav-content>
</mat-sidenav-container>
