<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>
                    How To (Mobile)
                </h1>
            </mat-card-title>
            <div class="custom_div">
                <mat-accordion class="example-headers-align">
                    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h4>Betting(How to)</h4>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                        <div class="main-div" fxLayout="column">
                            To bet using MTN, dial *165#. Select 4 (Payments); select 6 (Lotto & Sports Betting); then select 4 (Kagwirawo).
                            <br><br>
                            To bet using Airtel, dial *185#. Select 5 (Payments); select 3 (BETTING and GAMING); then select 1 (Kagwirawo).
                            <br><br>
                            You should see the KAGWIRAWO menu 
                          <!-- <div class="bet-div" fxLayout="row" style="margin-bottom: 1vw;">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/img/how-to-bet/01.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/img/how-to-bet/02.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/img/how-to-bet/03.jpg" alt="">
                          </div>
                          <div class="bet-second-div" fxLayout="row" style="margin-bottom: 1vw;">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/img/how-to-bet/04.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/img/how-to-bet/05.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/img/how-to-bet/06.jpg" alt="">
                          </div>
                          <div class="bet-third-div" fxLayout="row">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/img/how-to-bet/07.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/img/how-to-bet/08.jpg" alt="">
                          </div> -->
                        </div>                
                      <mat-action-row>
                        <button mat-button color="primary" (click)="nextStep()">Next</button>
                      </mat-action-row>
                    </mat-expansion-panel>
                  
                    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h4>Deposit money to your Account</h4>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
  
                      <div class="main-div" fxLayout="column">

                            To Deposit using MTN, dial *165#. Select 4 (Payments); select 6 (Lotto & Sports Betting); then select 4 (Kagwirawo); then select 2 (Deposit).
                            <br><br>
                            To Deposit using Airtel, dial *185#. Select 5 (Payments); select 3 (BETTING and GAMING); then select 1 (Kagwirawo); then select 2 (Deposit).
                            <br><br>
                            You should see the KAGWIRAWO menu
                        
                          <!-- <div class="bet-div" fxLayout="row" style="margin-bottom: 1vw;">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/payments/01.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/payments/02.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/payments/03.jpg" alt="">
                          </div>
                          <div class="bet-second-div" fxLayout="row" style="margin-bottom: 1vw;">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/mobile-howto/payments/04.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/mobile-howto/payments/05.jpg" alt="">
                          </div> -->
                        </div>  
  
                      <mat-action-row>
                        <button mat-button color="warn" (click)="prevStep()">Previous</button>
                        <button mat-button color="primary" (click)="nextStep()">Next</button>
                      </mat-action-row>
                    </mat-expansion-panel>
                  
                    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h4>Withdraw money from your Account</h4>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
  
                      <div class="main-div" fxLayout="column">

                            To Withdraw using MTN, dial *165#. Select 4 (Payments); select 6 (Lotto & Sports Betting); then select 4 (Kagwirawo); then select 4 (Withdraw).
                            <br><br>
                            To Withdraw using Airtel, dial *185#. Select 5 (Payments); select 3 (BETTING and GAMING); then select 1 (Kagwirawo); then select 4 (Withdraw).
                            <br><br>
                            You should see the KAGWIRAWO menu
                        
                          <!-- <div class="bet-div" fxLayout="row" style="margin-bottom: 1vw;">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/withdraw/01.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/withdraw/02.jpg" alt="">
                            <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/withdraw/03.jpg" alt="">
                          </div>
                          <div class="bet-second-div" fxLayout="row" style="margin-bottom: 1vw;">
                            <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/mobile-howto/withdraw/04.jpg" alt="">
                          </div> -->
                        </div>
  
                      <mat-action-row>
                        <button mat-button color="warn" (click)="prevStep()">Previous</button>
                        <button mat-button color="primary" (click)="nextStep()">Next</button>
                      </mat-action-row>
                    </mat-expansion-panel>
  
                    <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4>Checking your Kagwirawo Balance</h4>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
  
                        <div class="main-div" fxLayout="column">
                            To Check Balance using MTN, dial *165#. Select 4 (Payments); select 6 (Lotto & Sports Betting); then select 4 (Kagwirawo); then select 5 (Check Balance).
                            <br><br>
                            To Check Balance using Airtel, dial *185#. Select 5 (Payments); select 3 (BETTING and GAMING); then select 1 (Kagwirawo); then select 5 (Check Balance).
                            <br><br>
                            You should see the KAGWIRAWO menu 
                            <!-- <div class="bet-div" fxLayout="row" style="margin-bottom: 1vw;">
                              <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/balance/01.jpg" alt="">
                              <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/balance/02.jpg" alt="">
                            </div> -->
                          </div>
                    
                        <mat-action-row>
                          <button mat-button color="warn" (click)="prevStep()">Previous</button>
                          <button mat-button color="primary" (click)="nextStep()">Next</button>
                        </mat-action-row>
                      </mat-expansion-panel>
  
                      <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <h4>Register your Telephone</h4>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                            <div class="main-div" fxLayout="column">

                              To Register using MTN, dial *165#. Select 4 (Payments); select 6 (Lotto & Sports Betting); then select 4 (Kagwirawo); then select 6 (Register).
                              <br><br>
                              To Check Balance using Airtel, dial *185#. Select 5 (Payments); select 3 (BETTING and GAMING); then select 1 (Kagwirawo); then select 6 (Register).
                              <br><br>
                              You should see the KAGWIRAWO menu
                          
                                <!-- <div class="bet-div" fxLayout="row" style="margin-bottom: 1vw;">
                                  <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/register/01.jpg" alt="">
                                  <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/register/02.jpg" alt="">
                                  <img style="width: 150px; height: 180px; margin-right: 1vw;" src="../../assets/mobile-howto/register/03.jpg" alt="">
                                </div>
                                <div class="bet-second-div" fxLayout="row" style="margin-bottom: 1vw;">
                                  <img style="width: 150px; height: 180px; margin-right: 1vw; " src="../../assets/mobile-howto/register/04.jpg" alt="">
                                </div> -->
                            </div>
        
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">End</button>
                          </mat-action-row>
                        </mat-expansion-panel>
                  
                  </mat-accordion>
            </div>
        </div>
    </section>
</app-nav>