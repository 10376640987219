<app-nav>
    <section fxLayout="row">
        <div fxFlex="60" fxFlexOffset.gt-xs="20" fxFlex.xs="100">
            <mat-card-title>
                <h1>
                    About Kagwirawo
                </h1>
                <mat-divider></mat-divider>
                <br>
            </mat-card-title>
            <p>
                KAGWIRAWO is an electronic gaming platform that enables its users to engage in Sports Betting. The KAGWIRAWO brand and range of products is owned and run by Blue Cube LIMITED, a company incorporated and registered in Uganda and in business since 2007. Blue Cube LIMITED is duly lincensed by the National Lotteries Boad (NLB), under the Ministry of Finance Planning and Econonmic Development.
            </p>
            <p>
                KAGIRWAWO was the first of it's kind, at first, only available through USSD/mobile. Thanks to our our pioneer efforts, a lot of providers now see the value in adding the electronic wallet option to their offerings. Despite many followers, we still offer the best electronic sports betting platform, combining:
            </p>
            <ol>
                <li>Ease of use</li>
                <li>Reliability; Blue Cube's core business is IT and you are assured of the highest degree of security for your money and data</li>
                <li>Feature set; we're available online and on mobile (through USSD), with very intuitve interfaces.</li>
                <li>Instant payments, within minutes or just a couple of hours of games ending</li>
                <li>The best and <span style="font-weight:bold">MOST</span> responsive customer service; we believe customer is king, and you get that all-round experiece when you bet with KAGWIRAWO!</li>
            </ol>
        </div>
    </section>
</app-nav>
