<app-nav>
    <section fxLayout="column">
        <div fxFlex="60" fxFlex.xs="100">
            <mat-card-title>
                <h1>100% Bonus Promotion Terms & Conditions </h1>
            </mat-card-title>
            <p>
                (a). You must first place at least 2 bets that are equivalent to or greater than the amount of your first deposited for your bonus to be eligible.
            </p>
            <p>
                (b). You must have a minimum of 4 selections per bet/receipt.
            </p>
            <p>
                (c). You must have odds of 1.5 and  above per selection.
            </p>
            <p>
                (d). You will get 100% on your first deposit up to a maximum of UGX 50,000 after placing 2 or more qualifying bets, as stipulated above.
            </p>
            <p>
                (e). Only bets received through the website (www.kagwirawo.co.ug) and the mobile app qualify for the bonus.
            </p>
            <p>
                (f).This promotion begins 23/11/2022 and ends on 31/12/2022
            </p>
        </div>
        <!-- <div fxFlex="0" fxFlexOffset.gt-xs="5" fxFlex.xs="100">
            <mat-card-title>
                <h1>Kagwirawo Egabude Promo Guidelines </h1>
            </mat-card-title>
            <p>
                1) Place a bet On any world cup game & stand a chance to win mega prizes like TVs,Phones & hard cash 
            </p>
            <p>
                2)To be eligible for the promo, place a number of bets using either the website or ussd
            </p>
            <p>
                3)Winners will be announced every week after running a live draw live on Facebook.
            </p>
            <p>
                4) The promo shall run from 23rd November 2022 until 18th December 2022
            </p>
        </div> -->
    </section>
</app-nav>