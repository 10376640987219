import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payout-calculation',
  templateUrl: './payout-calculation.component.html',
  styleUrls: ['./payout-calculation.component.scss']
})
export class PayoutCalculationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
