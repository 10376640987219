import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-mobile',
  templateUrl: './how-to-mobile.component.html',
  styleUrls: ['./how-to-mobile.component.scss']
})
export class HowToMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

}
